@import url('https://fonts.googleapis.com/css2?family=manrope:ital,wght@0,100..900;1,100..900&family=Manrope:wght@200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif:ital,wght@0,100..900;1,100..900&display=swap');
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.manrope-regular {
  font-family: "Manrope", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
.manrope-semibold {
  font-family: "Manrope", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
}
.manrope-bold {
  font-family: "Manrope", sans-serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: normal;
}
.manrope-thini {
  font-family: "Manrope", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}
.noto-serif-bold {
  font-family: "Noto Serif", serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.body-font, .text-base{
  font-size: 1.1rem;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
